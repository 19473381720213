<template>
  <div>
    <MenuStyle1 />
    <PageHeader pageTitle="Mission And Scope" parentPage="About" />
    <MissionComponentVue />
    <Footer />
  </div>
</template>


<script>
import MenuStyle1 from "@/components/layout/MenuStyle1";
import PageHeader from "@/components/layout/PageHeader";
import Footer from "@/components/layout/Footer";
import MissionComponentVue from "../components/page_components/about_components/MissionComponent.vue";
export default {
  name: "Blogs",
  components: {
    MenuStyle1,
    PageHeader,
    MissionComponentVue,
    Footer,
  },
  metaInfo() {
    return {
      title: 'Mission And Scope | International Journal | IJIRE',
      meta: [
        {
          name: 'description',
          content: "Discover IJIRE's mission to advance Engineering with original research and our scope covering all major engineering disciplines and interdisciplinary fields",
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  }

};
</script>


<style scoped>
</style>



